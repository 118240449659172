'use client';

import { BarItem } from '@bts-web/design-system/component/bar-item';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  toggleStyles,
  ToggleStylesVariantProps,
} from 'bts-style-engine/recipes';

type ThemedToggleProps = ToggleStylesVariantProps & {
  variant?: 'small' | 'medium';
  onPressedChange?: (isOn: boolean) => void;
};

export const Toggle = ({
  variant = 'medium',
  onPressedChange,
  ...extraProps
}: ThemedToggleProps) => {
  const toggleSize = {
    small: {
      width: 40,
      height: 24,
    },
    medium: {
      width: 51,
      height: 31,
    },
  }[variant];

  return (
    <BarItem
      className={toggleStyles()}
      data-testid="toggle-wrapper"
      onPressedChange={onPressedChange}
      {...toggleSize}
      {...extraProps}
      icon={<Icon icon="toggle" {...toggleSize} data-element="icon" />}
    />
  );
};
