import(/* webpackMode: "eager", webpackExports: ["DatadogSetUser"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/apps/lbbw/app/monitoring/DatadogSetUser.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/apps/lbbw/base-components/ListCellsSelection/ListCellsSelectionRoot.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/apps/lbbw/base-components/ProgressCircle/ProgressCircle.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/apps/lbbw/base-components/Sheet/Sheet.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/apps/lbbw/base-components/Toggle/Toggle.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/BitpandaLogo.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/default-image/default-asset.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-coin.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-etc.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-etf.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-metal.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-stock.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/RaiffeisenLogo.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/AssetImage/AssetImage.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/ClickAwayListener/ClickAwayListener.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/ClickAwayListener/useClickAwayListener.ts");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/CopyToClipboard/CopyToClipboard.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DigitCodeInput/DigitCodeInput.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DigitCodeInput/DigitCodeInputPlayground/DigitCodeInputPlayground.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DocumentationExternalLink/DocumentationExternalLink.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/NoResults/NoResults.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/PortfolioAssets/PortfolioAssets.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/PriceVariation/PriceVariation.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/QRCode/QRCode.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/ReadOnlyTag/ReadOnlyTag.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/SearchBar/SearchBar.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/Select/Select.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/SortButton/SortButton.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/UserAccess/UserAccessModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/UserAccess/UserAccessWithRoleCheck.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/layout/InfoLayout/BitpandaBWhite.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo.ts");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/userinfo/UserInfoContext/UserInfoProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/utils/tests/components.mock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/error/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/accordion/src/lib/Accordion.use-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionContentImage"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/accordion/src/lib/AccordionContentImage.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/accordion/src/lib/AccordionItem.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/bar/src/lib/Bar.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/chart/src/lib/Area/Area.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/chart/src/lib/Donut/Donut.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/dialog/src/lib/Dialog.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/list-cells-selection/src/lib/Indicator.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/list-cells-selection/src/lib/Item.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/list-cells-selection/src/lib/Root.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/navigation-bar/src/lib/NavigationBar.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/progress-bar/src/lib/ProgressBar.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/progress-circle/src/lib/determinate/ProgressCircle.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/read-more/src/lib/ReadMore.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useClientTranslation"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/utils-lokalise/src/utils/translation.use-client.ts");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/utils-relay/src/lib/handleGqlErrors/handleGqlErrorsWithSnackbar.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Root","Trigger","Portal","Content"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/@radix-ui/react-dropdown-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/next/dist/client/link.js");
