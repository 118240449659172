'use client';

import { ReactElement } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

export type NavigationBarProps = {
  internal: {
    className: string;
    titleClassName?: string;
  };
  external: {
    title?: string | ReactElement;
    children?: React.ReactNode;
    isTitleCentered?: boolean;
    NavigationLeftSlot?: React.ReactElement;
    NavigationRightSlot?: React.ReactElement;
  };
};

export const NavigationBar = ({
  className,
  titleClassName,
  title,
  isTitleCentered = true,
  NavigationLeftSlot,
  NavigationRightSlot,
}: NavigationBarProps['internal'] & NavigationBarProps['external']) => {
  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className={className} data-testid="navigation-bar">
        {NavigationLeftSlot ? (
          <NavigationMenu.Item>{NavigationLeftSlot}</NavigationMenu.Item>
        ) : (
          <span
            data-element="empty-navigation-slot"
            data-testid="empty-navigation-slot"
          />
        )}

        {title && (
          <NavigationMenu.Item
            className={titleClassName}
            data-testid="navigation-bar-title"
            data-visual={isTitleCentered ? 'centered' : ''}
          >
            {title}
          </NavigationMenu.Item>
        )}

        <NavigationMenu.Item data-element="navigation-right-slot">
          {NavigationRightSlot}
        </NavigationMenu.Item>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};
