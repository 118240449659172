'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useSearchParams } from 'next/navigation';

const DatadogSetUser = ({ id }: { id?: string }) => {
  const searchParams = useSearchParams();

  const userId = id || searchParams.get('uid');

  if (userId) datadogRum.setUser({ id: userId });

  return null;
};

export { DatadogSetUser };
