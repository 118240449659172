'use client';

import { Sheet, type SheetProps } from '@bts-web/design-system/component/sheet';
import {
  sheetContentStyles,
  sheetOverlayStyles,
  type SheetContentStylesVariantProps,
} from 'bts-style-engine/recipes';

type ThemedSheetProps = SheetContentStylesVariantProps & SheetProps['external'];

const ThemedSheet = ({ visual, ...sheetProps }: ThemedSheetProps) => (
  <Sheet
    {...sheetProps}
    contentClassName={sheetContentStyles({ visual })}
    overlayClassName={sheetOverlayStyles()}
  />
);

export { ThemedSheet as Sheet, type ThemedSheetProps as SheetProps };
