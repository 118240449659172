'use client';

import { useEffect, useMemo, useState } from 'react';
import * as Progress from '@radix-ui/react-progress';

export interface ProgressCircleProps {
  internal: {
    className: string;
    indicatorClassName: string;
  };
  external: {
    duration: number;
    isLoading?: boolean;
    onLoaded: () => void;
  };
}

const LOADING_COUNT = 4;

const LOADING_AMOUNT = 25;

const LOADING_FULL_AMOUNT = LOADING_COUNT * LOADING_AMOUNT;

let loadingInterval: ReturnType<typeof setInterval> | null;

const clearIntervalFn = () => {
  if (loadingInterval) {
    clearInterval(loadingInterval);

    loadingInterval = null;
  }
};

/**
 * @notice only 1 progress circle per page issupported
 */
let isProgressPropertyRegistered = false;

export const ProgressCircle = ({
  className,
  indicatorClassName,
  duration,
  isLoading,
  onLoaded,
}: ProgressCircleProps['external'] & ProgressCircleProps['internal']) => {
  const [progress, setProgress] = useState(isLoading ? 0 : LOADING_FULL_AMOUNT);

  const intervalSize = useMemo(() => duration / LOADING_COUNT, [duration]);

  const indicatorCSSVariables: React.CSSProperties = useMemo(
    () =>
      ({
        '--progress': `${progress}%`,
      }) as React.CSSProperties,
    [progress],
  );

  // if progress has started (value of 0), begin the loading process
  useEffect(() => {
    if (progress === 0) {
      loadingInterval = setInterval(() => {
        setProgress((currentProgress) => currentProgress + LOADING_AMOUNT);
      }, intervalSize);
    }
  }, [intervalSize, progress]);

  // if the progress has reached the full value, clear the interval and call the onLoaded function
  useEffect(() => {
    if (progress === LOADING_FULL_AMOUNT && loadingInterval) {
      clearIntervalFn();

      onLoaded();
    }
  }, [progress, onLoaded]);

  // if the isLoading flag is set to true, start the loading process
  useEffect(() => {
    if (isLoading === true) {
      setProgress(0);
    }
  }, [isLoading]);

  // clear the progress interval when the component is unmounted
  useEffect(() => {
    // add --progress property for animating the loading circle from the recipe
    if (
      !isProgressPropertyRegistered &&
      'registerProperty' in (window.CSS ?? {})
    ) {
      window.CSS.registerProperty({
        name: '--progress',
        syntax: '<percentage>',
        inherits: false,
        initialValue: '100%',
      });

      isProgressPropertyRegistered = true;
    }

    document.addEventListener(
      'clear-progress-circle-interval',
      clearIntervalFn,
    );

    return () => {
      clearIntervalFn();

      document.removeEventListener(
        'clear-progress-circle-interval',
        clearIntervalFn,
      );
    };
  }, []);

  return (
    <Progress.Root
      className={className}
      data-testid="progress-circle"
      value={progress}
    >
      <Progress.Indicator
        className={indicatorClassName}
        style={indicatorCSSVariables}
        data-testid="progress-circle-indicator"
      />
    </Progress.Root>
  );
};

export default ProgressCircle;
