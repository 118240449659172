import { stack } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../common';

export function ErrorPageSkeleton() {
  return (
    <div
      data-testid="error-page-skeleton"
      className={stack({
        minHeight: '100vh',
        justifyContent: 'center',
        gap: 0,
      })}
    >
      <div
        className={stack({
          width: 'fit-content',
          padding: 'extra_large_2',
          gap: 'extra_small_2',
          alignItems: 'center',
          margin: 'auto',
        })}
      >
        <SkeletonElement
          testId="skeleton-error-title"
          width="232px"
          height="24px"
        />

        <div
          className={stack({
            gap: 'extra_small_3',
            alignItems: 'center',
            margin: 'auto',
          })}
        >
          <SkeletonElement
            testId="skeleton-error-first-line"
            width="248px"
            height="16px"
          />
          <SkeletonElement
            testId="skeleton-error-second-line"
            width="200px"
            height="16px"
          />
        </div>
      </div>
    </div>
  );
}
