export interface ProgressCircleIndeterminateProps {
  className: string;
}

export const ProgressCircleIndeterminate = ({
  className,
}: ProgressCircleIndeterminateProps) => (
  <div className={className} data-testid="progress-circle-indeterminate">
    <div data-element="spinner-container">
      <div data-element="spinner"></div>
    </div>
  </div>
);
