export function getCloudflareHeaders() {
  const {
    SSR_CF_ACCESS_CLIENT_ID: cfAccessClientId,
    SSR_CF_ACCESS_CLIENT_SECRET: cfAccessClientSecret,
  } = process.env;

  let cfHeaders = {};

  if (cfAccessClientId && cfAccessClientSecret) {
    cfHeaders = {
      'CF-Access-Client-Id': cfAccessClientId,
      'CF-Access-Client-Secret': cfAccessClientSecret,
    };
  }

  return cfHeaders;
}

export function getVercelHeaders({ onlyFor }: { onlyFor?: 'preview' } = {}) {
  const {
    VERCEL_ENV: vercelEnv,
    DEPLOYMENT_PROTECTION_BYPASS_SECRET: deploymentProtectionBypass,
  } = process.env;

  let vercelHeaders = {};

  if ((!onlyFor || onlyFor === vercelEnv) && deploymentProtectionBypass) {
    vercelHeaders = {
      'x-vercel-protection-bypass': deploymentProtectionBypass,
    };
  }

  return vercelHeaders;
}
