'use client';

import {
  ListCellsSelectionRoot,
  ListCellsSelectionRootProps,
} from '@bts-web/design-system/component/list-cells-selection';
import { listCellsSelectionRootStyles } from 'bts-style-engine/recipes';

const ThemedListCellsSelectionRoot = ({
  children,
  onValueChange,
  ...rest
}: ListCellsSelectionRootProps) => (
  <ListCellsSelectionRoot
    className={`${listCellsSelectionRootStyles()}`}
    onValueChange={onValueChange}
    {...rest}
  >
    {children}
  </ListCellsSelectionRoot>
);

export { ThemedListCellsSelectionRoot as ListCellsSelectionRoot };
