'use client';

import {
  ProgressCircle,
  ProgressCircleProps,
} from '@bts-web/design-system/component/progress-circle';
import {
  progressCircleStyles,
  progressCircleIndicatorStyles,
  type ProgressCircleStylesVariantProps,
} from 'bts-style-engine/recipes';

export type ThemedProgressCircleProps = ProgressCircleStylesVariantProps &
  ProgressCircleProps['external'];

const ThemedProgressCircle = ({
  size,
  ...progressCircleProps
}: ThemedProgressCircleProps) => {
  return (
    <ProgressCircle
      className={progressCircleStyles({ size })}
      indicatorClassName={progressCircleIndicatorStyles()}
      {...progressCircleProps}
    />
  );
};

export { ThemedProgressCircle as ProgressCircle };
